/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/slick-carousel@1.8.1/slick/slick.min.css
 * - /npm/formstone@1.4.22/dist/css/checkbox.css
 * - /npm/formstone@1.4.22/dist/css/dropdown.css
 * - /npm/formstone@1.4.22/dist/css/number.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
